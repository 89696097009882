import React from 'react';
import Styled from './styled';

export const Loading = () => {
  return (
    <Styled.Spinner>
      <Styled.SpinnerDot />
      <Styled.SpinnerDot />
      <Styled.SpinnerDot />
    </Styled.Spinner>
  );
};
