import styled from 'styled-components';
import { up } from 'styled-breakpoints';

const Styled = {
  Header: styled.div`
    display: flex;
    align-items: center;
    background: linear-gradient(-20deg, #2b5876 0%, #4e4376 100%);
    padding: 1.5em;

    ${up('mobileLarge')} {
      border-top-left-radius: 6px;
      border-top-right-radius: 6px;
      gap: 20px;
    }
  `,
  Body: styled.div`
    padding: 1em;
    background: white;
    border-bottom-right-radius: 6px;
    border-bottom-left-radius: 6px;
  `,
};

export default Styled;
