import React from 'react';
import Styled from './styled';
import { ReactComponent as UserCheck } from '../../common/icons/user-check.svg';
import { ReactComponent as UserMinus } from '../../common/icons/user-minus.svg';

interface Props {
  answered: boolean;
}

const UserIndicator = (props: Props) => {
  const { answered } = props;

  return (
    <Styled.UserIndicator positive={answered}>
      {answered && <UserCheck />}
      {!answered && <UserMinus />}
    </Styled.UserIndicator>
  );
};

export default UserIndicator;
