import styled from 'styled-components';
import { animated } from 'react-spring';

const Styled = {
  Overlay: styled(animated.div)`
    ${(props) => (props.hidden ? 'display: none;' : '')}
    position: fixed;
    top: 0px;
    left: 0px;
    right: 0px;
    bottom: 0px;
    background-color: rgba(0, 0, 0, 0.4);
  `,
  Content: styled(animated.div)`
    display: flex;
    flex-direction: column;
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.06), 0px 4px 6px rgba(0, 0, 0, 0.1);
    margin: 2em auto;
    max-width: max-content;
  `,
};

export default Styled;
