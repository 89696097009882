import styled from 'styled-components';

const Styled = {
  Subheader: styled.div`
    padding: 1em;
    background: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: space-between;
    align-items: center;
  `,
  Title: styled.h1`
    font-family: ${(props) => props.theme.typography.titleFontFamily};
    color: white;
    margin: 0;
    font-size: ${(props) => props.theme.typography.titleFontSize};
    font-weight: ${(props) => props.theme.typography.titleFontWeight};
  `,
};

export default Styled;
