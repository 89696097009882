import React from 'react';
import Styled from './styled';
import CommonStyled from '../../common/styled';

type Props = {
  title?: String;
  children?: React.ReactNode;
  style?: any;
  vertical?: boolean;
};

const Section = (props: Props) => {
  const { title, children, style, vertical } = props;

  return (
    <Styled.Section style={style}>
      {title && <CommonStyled.Title>{title}</CommonStyled.Title>}
      <Styled.Centered vertical={vertical}>{children}</Styled.Centered>
    </Styled.Section>
  );
};

Section.defaultProps = {
  children: null,
};

export default Section;
