import React, { useState } from 'react';
import StyledFirebaseAuth from 'react-firebaseui/StyledFirebaseAuth';
import Styled from './styled';
import CommonStyled from '../../common/styled';
import Modal from '../Modal';
import { useAuth } from 'reactfire';
import CreateAccount from './CreateAccount';

interface Props {
  onClose: () => void;
}

const AlreadyRegistered = (props: any) => {
  const { email, auth, providerName } = props;

  let provider = useAuth.GoogleAuthProvider.PROVIDER_ID;

  switch (providerName) {
    case 'Google':
      provider = useAuth.GoogleAuthProvider.PROVIDER_ID;
      break;
  }

  const uiConfig = {
    popUpMode: true,
    signInFlow: 'popup',
    signInOptions: [
      {
        provider,
        fullLabel: `Sign in with ${providerName} again`,
        loginHintKey: 'login_hint',
        customParameters: {
          prompt: 'select_account',
        },
      },
    ],
    callbacks: {
      // Avoid redirects after sign-in.
      signInSuccessWithAuthResult: () => false,
    },
  };

  return (
    <>
      <CommonStyled.H2>You already have an account</CommonStyled.H2>
      <CommonStyled.Body>
        The email <b>{email}</b>
        <br />
        was used to sign in with {providerName} before.
      </CommonStyled.Body>
      <StyledFirebaseAuth uiConfig={uiConfig} firebaseAuth={auth} />
    </>
  );
};

const EmailForm = (props: any) => {
  const { setShowEmailForm, auth } = props;
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [providerName, setProviderName] = useState('');

  const handleEmailCheck = async (e: React.MouseEvent) => {
    e.preventDefault();
    await auth.fetchSignInMethodsForEmail(email).then((ids: any) => {
      if (ids.includes('password')) {
        setProviderName('existingEmail');
      } else if (ids.includes('google.com')) {
        setProviderName('Google');
      } else {
        setProviderName('newEmail');
      }
    });
  };

  const handleSignIn = async (e: React.MouseEvent) => {
    e.preventDefault();
    await auth
      .signInWithEmailAndPassword(email, password)
      .catch((error: any) => {
        console.log(error);
      });
  };

  if (providerName === '') {
    return (
      <>
        <CommonStyled.Overline>Enter your email</CommonStyled.Overline>
        <CommonStyled.Form>
          <CommonStyled.Input
            placeholder="Email Address"
            type="email"
            autoComplete="username"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            required
          />
          <CommonStyled.ButtonGroup>
            <CommonStyled.Button onClick={() => setShowEmailForm(false)}>
              Cancel
            </CommonStyled.Button>
            <CommonStyled.Button primary onClick={(e) => handleEmailCheck(e)}>
              Next
            </CommonStyled.Button>
          </CommonStyled.ButtonGroup>
        </CommonStyled.Form>
      </>
    );
  } else {
    if (providerName === 'existingEmail') {
      return (
        <>
          <CommonStyled.Overline>Enter your password</CommonStyled.Overline>
          <CommonStyled.Form>
            <CommonStyled.Input
              placeholder="Email Address"
              type="email"
              autoComplete="username"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              required
            />
            <CommonStyled.Input
              placeholder="Password"
              type="password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              required
            />
            <CommonStyled.ButtonGroup>
              <CommonStyled.Button onClick={() => setShowEmailForm(false)}>
                Cancel
              </CommonStyled.Button>
              <CommonStyled.Button primary onClick={(e) => handleSignIn(e)}>
                Sign In
              </CommonStyled.Button>
            </CommonStyled.ButtonGroup>
          </CommonStyled.Form>
        </>
      );
    }
    if (providerName === 'newEmail') {
      return (
        <CreateAccount email={email} setShowEmailForm={setShowEmailForm} />
      );
    }
    return (
      <AlreadyRegistered
        email={email}
        auth={auth}
        providerName={providerName}
      />
    );
  }
};

const SignInForm = (props: Props) => {
  const [modalOpen] = useState(true);
  const auth = useAuth();
  const [showEmailForm, setShowEmailForm] = useState(false);

  const uiConfig = {
    popUpMode: true,
    signInFlow: 'popup',
    signInOptions: [useAuth.GoogleAuthProvider.PROVIDER_ID],
    callbacks: {
      // Avoid redirects after sign-in.
      signInSuccessWithAuthResult: () => false,
    },
  };

  const { onClose } = props;

  return (
    <Modal modalOpen={modalOpen} onClose={() => onClose()}>
      <Styled.Header>
        <CommonStyled.Body color="white" fontSize={24}>
          Sign In To Play
        </CommonStyled.Body>
      </Styled.Header>
      <Styled.Body>
        {showEmailForm && (
          <EmailForm
            auth={auth}
            setShowEmailForm={(value: boolean) => setShowEmailForm(value)}
          />
        )}
        {!showEmailForm && (
          <div className="sign-in">
            <div className="firebaseui-card-content">
              <div className="firebaseui-list-item">
                <CommonStyled.Button
                  className="firebaseui-idp-button"
                  primary
                  onClick={() => setShowEmailForm(true)}
                >
                  <span className="firebaseui-idp-icon-wrapper">
                    <img
                      className="firebaseui-idp-icon"
                      alt=""
                      src="https://www.gstatic.com/firebasejs/ui/2.0.0/images/auth/mail.svg"
                    />
                  </span>
                  <span className="firebaseui-idp-text firebaseui-idp-text-long">
                    Sign in with email
                  </span>
                </CommonStyled.Button>
              </div>
            </div>
            <StyledFirebaseAuth uiConfig={uiConfig} firebaseAuth={auth} />
          </div>
        )}
      </Styled.Body>
    </Modal>
  );
};

export default SignInForm;
