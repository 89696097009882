import React, { useState } from 'react';
import Styled from './styled';

interface Props {}

const Answer = (props: Props) => {
  const [answer, setAnswer] = useState('');

  return (
    <Styled.Answer>
      <Styled.Input
        value={answer}
        onChange={(e) => setAnswer(e.target.value)}
        placeholder="Type your answer here..."
      />
      &nbsp;&nbsp;
      <Styled.Submit disabled={!answer} onClick={() => alert('test')}>
        Submit
      </Styled.Submit>
    </Styled.Answer>
  );
};

export default Answer;
