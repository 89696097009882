import React, { Suspense } from 'react';
import { useUser, useFirestore, useDatabase, AuthCheck } from 'reactfire';
import { Loading } from '../common/Loading';
import Styled from '../common/styled';
import ErrorBoundary from '../components/ErrorBoundary';
import { Header } from '../components/Header';
import Players from '../components/Players';
import Section from '../components/Section';
import Subheader from '../components/Subheader';
import Question from '../components/Question';
import Answer from '../components/Answer';
import { User } from 'firebase';

const Home = () => {
  const user = useUser<User>();
  const firestore = useFirestore;
  const database = useDatabase;

  if (user) {
    console.log(user);
    const userRef = firestore().collection('players').doc(user.uid);

    userRef.get().then((snapshot) => {
      if (!snapshot.exists) {
        userRef.set({
          name: user.displayName,
          photoURL: user.photoURL,
        });
      }
    });

    const userStatusDatabaseRef = database().ref('/status/' + user.uid);

    const isOfflineForDatabase = {
      state: 'offline',
      last_changed: database.ServerValue.TIMESTAMP,
    };

    const isOnlineForDatabase = {
      state: 'online',
      last_changed: database.ServerValue.TIMESTAMP,
    };

    database()
      .ref('.info/connected')
      .on('value', function (snapshot: any) {
        userStatusDatabaseRef
          .onDisconnect()
          .set(isOfflineForDatabase)
          .then(() => {
            userStatusDatabaseRef.update(isOnlineForDatabase);
          });
      });
  }

  return (
    <Styled.Container>
      <Header user={user ? user.uid : null} />
      <Subheader />
      <Section vertical title="Round 1/5 - Technology">
        <Question />
        <Answer />
      </Section>
      <ErrorBoundary>
        <Suspense fallback={<Loading />}>
          <AuthCheck fallback={<></>}>
            <Players />
          </AuthCheck>
        </Suspense>
      </ErrorBoundary>
    </Styled.Container>
  );
};

export default Home;
