import React, { useState } from 'react';
import CommonStyled from '../../../common/styled';
import { useAuth } from 'reactfire';

interface Props {
  email: string;
  setShowEmailForm: (value: boolean) => {};
}

const CreateAccount = (props: Props) => {
  const auth = useAuth();
  const { email, setShowEmailForm } = props;
  const [error, setError] = useState('');
  const [displayName, setDisplayName] = useState('');
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');

  const handleSubmit = async (e: React.MouseEvent) => {
    e.preventDefault();
    await auth
      .createUserWithEmailAndPassword(email, password)
      .catch((error: any) => {
        setError(error.message);
      })
      .then(async () => {
        await auth.currentUser?.updateProfile({
          displayName,
        });
      });
  };

  return (
    <>
      <CommonStyled.Overline>Complete account setup</CommonStyled.Overline>
      <CommonStyled.Form>
        {error && <p>{error}</p>}
        <CommonStyled.Input
          placeholder="Email Address"
          type="email"
          autoComplete="username"
          value={email}
          disabled
          required
        />
        <CommonStyled.Input
          placeholder="Password"
          type="password"
          autoComplete="new-password"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
          required
        />
        <CommonStyled.Input
          placeholder="Confirm Password"
          type="password"
          autoComplete="confirm-password"
          value={confirmPassword}
          onChange={(e) => setConfirmPassword(e.target.value)}
          required
        />
        <CommonStyled.Input
          placeholder="Display Name"
          type="text"
          autoComplete="name"
          value={displayName}
          onChange={(e) => setDisplayName(e.target.value)}
          required
        />
        <CommonStyled.ButtonGroup>
          <CommonStyled.Button onClick={() => setShowEmailForm(false)}>
            Cancel
          </CommonStyled.Button>
          <CommonStyled.Button primary onClick={(e) => handleSubmit(e)}>
            Next
          </CommonStyled.Button>
        </CommonStyled.ButtonGroup>
      </CommonStyled.Form>
    </>
  );
};

export default CreateAccount;
