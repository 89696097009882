import styled, { createGlobalStyle, css } from 'styled-components';

interface BodyProps {
  fontWeight?: number;
  fontSize?: number;
  padding?: string;
  margin?: boolean;
}

export type ButtonProps = {
  primary?: boolean;
  children?: React.ReactNode;
  marginRight?: boolean;
};

const ButtonDefault = css`
  cursor: pointer;
  font-family: Montserrat;
  font-weight: 500;
  font-size: 14px;
  text-transform: uppercase;
  color: ${(props: ButtonProps) =>
    props.primary ? 'white' : 'rgba(0, 0, 0, 0.7)'};
  padding: 12px 20px;
  border-radius: 0.5em;
  border: none;
  background: ${(props: ButtonProps) =>
    props.primary ? '#65689F !important' : 'white'};
  box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.25);
  line-height: 1.5em;

  ${(props: any) =>
    props.marginRight &&
    css`
      margin-right: 0.25em;
    `}

  svg {
    height: 1em;
    width: 1.3em;
    font-size: 20px;
    margin-right: 10px;
    display: block;
  }
`;

const Styled = {
  Global: createGlobalStyle`
    body {
      font-family: ${(props) => props.theme.typography.baseFontFamily};
      background: #374777;
      font-size: ${(props) => props.theme.typography.baseFontSize};
      margin: 0;

      #firebaseui_container {
        .firebaseui-text,
        .firebaseui-subtitle,
        .firebaseui-card-header {
          font-family: ${(props) => props.theme.typography.baseFontFamily};
        }
        
        .mdl-card {
          box-shadow: none;
          min-height: auto;
        }
      }

      label.mdl-textfield__label.firebaseui-label {
        display: none;
      }

      .sign-in {
        .firebaseui-list-item {
          display: flex;
        }
      }

      .firebaseui-idp-button,
      .firebaseui-id-submit,
      .firebaseui-id-secondary-link {
        ${ButtonDefault}
        height: auto;
        max-width: unset;
        width: auto;
        flex: 1;
        margin: 0;
      }

      .firebaseui-id-secondary-link {
        background: white;
        color: rgba(0, 0, 0, .4);
      }

      .firebaseui-form-actions {
        display: flex;
        justify-content: space-between;
      }

      .firebaseui-id-dismiss-info-bar {
        position: relative;
      }
      
      .firebaseui-info-bar {
        position: relative;
        left: 0;
        right: 0;
        font-family: ${(props) => props.theme.typography.baseFontFamily};
        border: none;
        text-align: left;
        box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.25);
        border-radius: 6px;
        background: white;
      }
    }
  `,
  Container: styled.div`
    flex-grow: 1;
    margin: 0 auto;
    justify-content: center;
    width: auto;
  `,
  Body: styled.p<BodyProps>`
    font-style: normal;
    font-weight: ${(props) => (props.fontWeight ? props.fontWeight : '400')};
    font-size: ${(props) => (props.fontSize ? props.fontSize : '16')}px;
    line-height: 1.2;
    ${(props) => (!props.margin ? 'margin: 0;' : '')}
    color: ${(props) => (props.color ? props.color : 'rgba(0, 0, 0)')};
    ${(props) => (props.padding ? `padding: ${props.padding};` : '')}
  `,
  Overline: styled.p`
    margin: 5px 0;
    font-family: Montserrat;
    font-style: normal;
    font-weight: 600;
    font-size: 12px;
    line-height: 15px;
    text-transform: uppercase;
    color: ${(props) => (props.color ? props.color : 'rgba(0, 0, 0, 0.6)')};
  `,
  H2: styled.h2`
    font-weight: 500;
  `,
  Title: styled.h3`
    text-transform: uppercase;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    color: rgba(255, 255, 255, 0.4);
    text-align: center;
  `,
  DoubleLine: styled.div<{ right?: boolean }>`
    text-align: ${(props) => (props.right ? 'right' : 'left')};
  `,
  Button: styled.button<ButtonProps>`
    ${ButtonDefault}
  `,
  ButtonGroup: styled.div`
    display: flex;
    justify-content: space-between;
  `,
  Chip: styled.div<ButtonProps>`
    font-family: Montserrat;
    font-weight: 600;
    max-height: 3em;
    font-size: 0.75em;
    text-transform: uppercase;
    color: rgba(0, 0, 0, 0.7);
    background: white;
    border: 1px solid #d2d6dc;
    box-shadow: 0 1px 2px rgba(0, 0, 0, 0.1);
    align-items: center;
    align-self: center;
    padding: 0.7em 1em;
    display: inline-flex;
    border-radius: 2em;
    line-height: 1.5em;
    cursor: help;

    svg {
      height: 1em;
      font-size: 20px;
      width: 1.3em;
      margin-right: 10px;
      display: block;
    }
  `,
  Spinner: styled.div`
    margin: 100px auto 0;
    width: 70px;
    text-align: center;
  `,
  SpinnerDot: styled.div`
    width: 18px;
    height: 18px;
    background: linear-gradient(90deg, #02aab0 0%, #00cdac 100%);
    box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.25);
    border-radius: 100%;
    display: inline-block;
    -webkit-animation: sk-bouncedelay 1.4s infinite ease-in-out both;
    animation: sk-bouncedelay 1.4s infinite ease-in-out both;

    &:nth-child(1) {
      -webkit-animation-delay: -0.32s;
      animation-delay: -0.32s;
    }
    &:nth-child(2) {
      -webkit-animation-delay: -0.16s;
      animation-delay: -0.16s;
    }

    @-webkit-keyframes sk-bouncedelay {
      0%,
      80%,
      100% {
        -webkit-transform: scale(0);
      }
      40% {
        -webkit-transform: scale(1);
      }
    }

    @keyframes sk-bouncedelay {
      0%,
      80%,
      100% {
        -webkit-transform: scale(0);
        transform: scale(0);
      }
      40% {
        -webkit-transform: scale(1);
        transform: scale(1);
      }
    }
  `,
  Card: styled.div`
    border: 1px solid blue;
    text-align: center;
  `,
  Form: styled.form`
    display: flex;
    flex-direction: column;
  `,
  Input: styled.input<{ dark?: boolean }>`
    font-family: ${(props) => props.theme.typography.baseFontFamily};
    background: ${(props) =>
      props.dark ? 'rgba(0, 0, 0, 0.4)' : 'rgba(0, 0, 0, 0.1)'};
    color: ${(props) =>
      props.dark ? 'rgba(255, 255, 255, 0.75)' : 'rgba(0, 0, 0, 0.5)'};
    padding: 0.8em 1em;
    border-radius: 8px;
    font-size: 16px;
    font-weight: 500;
    min-width: 260px;
    flex-grow: 1;
    border: none;
    border-color: transparent;
    margin: 1em 0;

    &::placeholder {
      color: ${(props) =>
        props.dark ? 'rgba(255, 255, 255, 0.5)' : 'rgba(0, 0, 0, 0.5)'};
    }
  `,
};

export default Styled;
