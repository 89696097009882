import { up } from 'styled-breakpoints';
import styled from 'styled-components';
import CommonStyled from '../../common/styled';

const Styled = {
  Answer: styled.div`
    display: flex;
    width: 100%;
    padding: 1em;

    ${up('tablet')} {
      width: 800px;
    }
  `,
  Input: styled.input`
    font-family: ${(props) => props.theme.typography.baseFontFamily};
    background: rgba(0, 0, 0, 0.4);
    color: rgba(255, 255, 255, 0.75);
    padding: 0.8em 1em;
    border-radius: 8px;
    font-size: 16px;
    flex-grow: 1;
    border: none;
    border-color: transparent;

    &::placeholder {
      color: rgba(255, 255, 255, 0.5);
    }
  `,
  Submit: styled(CommonStyled.Button)`
    background: linear-gradient(90deg, #02aab0 0%, #00cdac 100%);
    color: white;
    opacity: ${(props) => (props.disabled ? '0.4' : '1')};
  `,
};

export default Styled;
