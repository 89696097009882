import React, { Suspense } from 'react';
import { useFirestore, useFirestoreDocData } from 'reactfire';
import styled from 'styled-components';
import { Loading } from '../common/Loading';

type StyledHeaderProps = {
  large: boolean;
};

const Styled = {
  Header: styled.header`
    display: flex;
    justify-content: ${(props: StyledHeaderProps) =>
      props.large ? 'center' : 'space-between'};
    align-items: ${(props: StyledHeaderProps) =>
      props.large ? 'center' : 'unset'};
    padding: 0 1.5rem;
    background: rgba(0, 0, 0, 0.4);
    height: 120px;
  `,
  Title: styled.h1`
    font-family: ${(props) => props.theme.typography.titleFontFamily};
    color: white;
    margin: 0;
    font-size: ${(props) => props.theme.typography.titleFontSize};
    font-weight: ${(props) => props.theme.typography.titleFontWeight};
  `,
  Overline: styled.p`
    font-size: ${(props) => props.theme.typography.overlineFontSize};
    color: white;
    text-transform: uppercase;
    font-weight: ${(props) => props.theme.typography.overlineFontWeight};
  `,
};

export type HeaderProps = {
  user: string | null;
};

function Title() {
  const quizRef = useFirestore().collection('quiz').doc('1');
  const quiz: any = useFirestoreDocData(quizRef);

  return <Styled.Title>{quiz.title}</Styled.Title>;
}

export const Header: React.FC<HeaderProps> = (props: HeaderProps) => {
  return (
    <Suspense fallback={<Loading />}>
      <Styled.Header large={true}>
        <Title />
      </Styled.Header>
    </Suspense>
  );
};
