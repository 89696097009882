import styled from 'styled-components';

const Styled = {
  UserIndicator: styled.div<{ positive?: boolean }>`
    border-top-left-radius: 8px;
    padding: 0.4em 1em;
    position: absolute;
    right: 0;
    bottom: 50px;
    z-index: 99999;
    background: linear-gradient(
      90deg,
      ${(props) =>
        props.positive
          ? '#02AAB0 0%, #00CDAC 100%'
          : '#6A7B90 0%, #7D8EA5 100%'}
    );

    svg {
      path {
        stroke: rgba(255, 255, 255, 0.5);
      }
    }
  `,
};

export default Styled;
