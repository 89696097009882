import styled from 'styled-components';
import { animated } from 'react-spring';

const Styled = {
  Section: styled(animated.div)`
    padding: 15px 0;
  `,
  Centered: styled.div<{ vertical?: boolean }>`
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: ${(props) => (props.vertical ? 'column' : 'row')};
  `,
};

export default Styled;
