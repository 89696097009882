import React, { useState } from 'react';
import { animated, useTransition } from 'react-spring';
import { useDatabase } from 'reactfire';
import Styled from './styled';
import Player from '../Player';
import Section from '../Section';

interface Props {}

const Players = (props: Props) => {
  const database = useDatabase;
  const [players, setPlayers] = useState<string[]>();

  const onlinePlayersRef = database()
    .ref('/status')
    .orderByChild('state')
    .equalTo('online');

  onlinePlayersRef.once('value', function (dataSnapshot) {
    console.log(Object.keys(dataSnapshot.val()));
    if (dataSnapshot.exists() && Object.keys(dataSnapshot.val()) !== players) {
      setPlayers(Object.keys(dataSnapshot.val()));
    }
  });

  const playerTransitions = useTransition(players, {
    keys: (id: string) => id,
    from: { transform: 'translate3d(0,1000px,0) scale(0)' },
    enter: { transform: 'translate3d(0,0,0) scale(1)' },
    leave: { transform: 'translate3d(0,1000px,0) scale(0)' },
  });

  return (
    <Section>
      <Styled.Grid>
        {playerTransitions(
          (style, item, t, i) =>
            item && (
              <animated.div key={i} style={style}>
                <Player id={item} />
              </animated.div>
            )
        )}
      </Styled.Grid>
    </Section>
  );
};

export default Players;
