import { DefaultTheme } from 'styled-components';

const theme: DefaultTheme = {
  gap: '30px',
  breakpoints: {
    mobileSmall: '321px',
    mobile: '376px',
    mobileLarge: '426px',
    tabletSmall: '599px',
    tablet: '769px',
    desktop: '960px',
    widescreen: '1152px', // 1
    widescreenLarge: '1376px',
  },
  typography: {
    baseFontFamily: "'Montserrat', sans-serif",
    baseFontSize: '16px',
    titleFontFamily: "'Noteworthy', sans-serif",
    titleFontSize: '36px',
    titleFontWeight: '400',
    overlineFontSize: '13px',
    overlineFontWeight: '600',
  },
};

export default theme;
