import React, { useState, useEffect } from 'react';
import { useFirestoreDocData, useFirestore } from 'reactfire';
import styled from 'styled-components';
import User from '../User';
import UserIndicator from '../UserIndicator';

interface Props {
  id: string;
}

const Styled = {
  Card: styled.div`
    position: relative;
    width: 100%;
    height: 100%;
  `,
  Name: styled.div`
    width: 100%;
    background: linear-gradient(
      90deg,
      rgba(113, 125, 161, 1) 0%,
      rgba(124, 136, 172, 1) 100%
    );
    color: white;
    font-weight: 600;
    padding: 10px 0;
    line-height: 1;
    display: flex;
    justify-content: center;
    text-align: center;
    border-bottom-left-radius: 8px;
    border-bottom-right-radius: 8px;
  `,
  Video: styled.video`
    width: 100%;
    height: 250px;
    background: rgba(25, 32, 53, 1);
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
    object-fit: cover;
    transform: rotateY(180deg);
    display: block;
  `,
};

const getWebcam = async () => {
  const constraints = { video: true, audio: false };
  return await navigator.mediaDevices.getUserMedia(constraints);
};

interface IPlayer {
  name: string;
  photoURL: string;
}

const Player = (props: Props) => {
  const [answered] = useState(false);
  const [stream] = useState(getWebcam());
  const video = document.querySelector('video');
  const { id } = props;

  const item = useFirestoreDocData<IPlayer>(
    useFirestore().collection('players').doc(id)
  );

  useEffect(() => {
    stream.then((s) => {
      if (video && s) {
        video.srcObject = s;
      }
    });
  }, [stream, video]);

  return (
    <Styled.Card>
      <Styled.Video autoPlay loop controls={false} />
      <UserIndicator answered={answered} />
      <Styled.Name>
        <User name={item.name} avatar={item.photoURL} small />
      </Styled.Name>
    </Styled.Card>
  );
};

export default Player;
