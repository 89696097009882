import React from 'react';
import Styled from './styled';
import CommonStyled from '../../common/styled';

interface Props {
  name?: string | null;
  avatar?: string | null;
  small?: boolean;
}

const User = (props: Props) => {
  const { avatar, name, small } = props;

  return (
    <Styled.User>
      {avatar && <Styled.Avatar src={avatar} alt="" />}
      {name && (
        <CommonStyled.Body color="white" fontSize={small ? 18 : 24}>
          {name.substr(0, name.indexOf(' '))}
        </CommonStyled.Body>
      )}
    </Styled.User>
  );
};

export default User;
