import { up } from 'styled-breakpoints';
import styled from 'styled-components';

type GridProps = {
  size?: string;
};

const Styled = {
  Grid: styled.div<GridProps>`
    display: grid;
    gap: ${(props) => props.theme.gap} ${(props) => props.theme.gap};
    max-width: 100vw;
    justify-items: center;
    ${(props) =>
      props.size === 'tiny'
        ? 'grid-template-columns: repeat(auto-fill, 150px);'
        : props.size === 'small'
        ? 'grid-template-columns: repeat(auto-fill, 200px);'
        : 'grid-template-columns: repeat(auto-fill, 300px);'}

    ${up('mobile')} {
      max-width: calc(100vw - 60px);
    }

    ${up('widescreenLarge')} {
      max-width: 1260px;
    }
  `,
};

export default Styled;
