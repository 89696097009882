import React, { Suspense } from 'react';
import { preloadFirestore, useFirebaseApp } from 'reactfire';
import { Loading } from './common/Loading';
import Styled from './common/styled';
import ErrorBoundary from './components/ErrorBoundary';
import Home from './views/Home';

const preloadSDKs = (firebaseApp: any) =>
  Promise.all([
    preloadFirestore({
      firebaseApp,
      async setup(firestore) {
        const initalizedStore = firestore();
        // lets only use local emulator in dev env
        if (process.env.NODE_ENV !== 'production') {
          initalizedStore.settings({ host: 'localhost:8080', ssl: false });
        }

        await initalizedStore.enablePersistence();

        return initalizedStore;
      },
    }),
    // preloadAuth({
    //   firebaseApp,
    //   async setup(auth) {
    //     const authentication = auth();

    //     if (process.env.NODE_ENV !== "production") {
    //       authentication.useEmulator('http://localhost:9099/');
    //     }
    //   }
    // })
  ]);

function App() {
  const firebaseApp = useFirebaseApp();

  preloadSDKs(firebaseApp);

  return (
    <>
      <Styled.Global />
      <ErrorBoundary>
        <Suspense fallback={<Loading />}>
          <Home />
        </Suspense>
      </ErrorBoundary>
    </>
  );
}

export default App;
