import styled from 'styled-components';

const Styled = {
  User: styled.div`
    display: flex;
    align-items: center;
  `,
  Avatar: styled.img`
    width: 30px;
    height: 30px;
    border-radius: 30px;
    margin-right: 0.5em;
  `,
};

export default Styled;
