import styled from 'styled-components';

const Styled = {
  Question: styled.div`
    display: flex;
  `,
  Indicator: styled.div`
    background: linear-gradient(90deg, #ec6f66 0%, #f3a183 100%);
    color: white;
    padding: 1em;
    text-transform: uppercase;
    border-top-left-radius: 8px;
    border-bottom-left-radius: 8px;
  `,
  Text: styled.div`
    background: linear-gradient(90deg, #d5dee7 0%, #e8ebf2 50%, #e2e7ed 100%);
    color: black;
    padding: 1em;
    border-top-right-radius: 8px;
    border-bottom-right-radius: 8px;
    font-weight: 500;
  `,
};

export default Styled;
