import React from 'react';
import Styled from './styled';

interface Props {}

const Question = (props: Props) => {
  return (
    <Styled.Question>
      <Styled.Indicator>Question 1/5</Styled.Indicator>
      <Styled.Text>This is a test question?</Styled.Text>
    </Styled.Question>
  );
};

export default Question;
