import React, { useState } from 'react';
import Styled from './styled';
import CommonStyled from '../../common/styled';
import { AuthCheck, useAuth, useUser } from 'reactfire';
import SignInForm from '../SignInForm';
import { User as IUser } from 'firebase';
import User from '../User';

interface Props {}

const signOut = (auth: any) => auth.signOut();

const UserDetails = () => {
  const { displayName, photoURL } = useUser<IUser>();

  return (
    <CommonStyled.DoubleLine>
      <CommonStyled.Overline color="rgba(255, 255, 255, 0.8)">
        You are playing as
      </CommonStyled.Overline>
      <User name={displayName} avatar={photoURL} />
    </CommonStyled.DoubleLine>
  );
};

const SignIn = () => {
  const [expanded, setExpanded] = useState(false);

  return (
    <div>
      <CommonStyled.Button
        primary
        marginRight
        onClick={() => setExpanded(true)}
      >
        Sign In To Play
      </CommonStyled.Button>
      &nbsp;&nbsp;
      {expanded && <SignInForm onClose={() => setExpanded(false)} />}
    </div>
  );
};

const Subheader = (props: Props) => {
  const auth = useAuth();

  return (
    <Styled.Subheader>
      <AuthCheck fallback={<SignIn />}>
        <UserDetails />
        <CommonStyled.Button primary onClick={() => signOut(auth)}>
          Sign Out
        </CommonStyled.Button>
      </AuthCheck>
    </Styled.Subheader>
  );
};

export default Subheader;
